<template>
  <div class="moblie_pay">
    <div class="moblie_pay_header">
      <div class="moblie_pay_header_content">
        <div class="moblie_pay_header_time_repayment">还款</div>
        <div class="moblie_pay_header_time_box">
          <div class="moblie_pay_header_time_title">交易稍后到期</div>
          <div class="moblie_pay_header_time_info">
            <div class="moblie_pay_header_time_item">
              <div class="moblie_pay_header_time">{{ hr }}</div>
              <div class="moblie_pay_header_text">时</div>
            </div>
            <div class="moblie_pay_header_time_item">
              <div class="moblie_pay_header_time">{{ min }}</div>
              <div class="moblie_pay_header_text">分</div>
            </div>
            <div class="moblie_pay_header_time_item">
              <div class="moblie_pay_header_time">{{ sec }}</div>
              <div class="moblie_pay_header_text">秒</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="moblie_pay_container">
      <div class="moblie_pay_container_info">
        <div class="moblie_pay_container_item moblie_pay_container_item_header">
          <div class="moblie_pay_container_label">账单信息</div>
          <div class="moblie_pay_container_value">
            <div class="moblie_pay_header_img_box">
              <img class="moblie_pay_header_img" src="@/assets/cardPay/wepay_m.png" alt="">
            </div>
          </div>
        </div>
        <div class="moblie_pay_container_item">
          <div class="moblie_pay_container_label">支付金额</div>
          <div class="moblie_pay_container_value">{{ formatMoney(repaymentData.amount) }}</div>
        </div>
        <div class="moblie_pay_container_item">
          <div class="moblie_pay_container_label">平台交易号</div>
          <div class="moblie_pay_container_value">{{ repaymentData.platformReference }}</div>
        </div>
        <div class="moblie_pay_container_item">
          <div class="moblie_pay_container_label">收款银行</div>
          <div class="moblie_pay_container_value">
            {{ repaymentData.bankName }}
            <img @click="onCopy(repaymentData.bankName)" class="moblie_pay_container_copy"
                 src="@/assets/cardPay/copy_m.png" alt="">
          </div>
        </div>
        <div class="moblie_pay_container_item">
          <div class="moblie_pay_container_label">收款账户名</div>
          <div class="moblie_pay_container_value">
            {{ repaymentData.accountName }}
            <img @click="onCopy(repaymentData.accountName)" class="moblie_pay_container_copy"
                 src="@/assets/cardPay/copy_m.png" alt="">
          </div>
        </div>
        <div class="moblie_pay_container_item">
          <div class="moblie_pay_container_label">收款账户号</div>
          <div class="moblie_pay_container_value">
            {{ repaymentData.accountNumber }}
            <img @click="onCopy(repaymentData.accountNumber)" class="moblie_pay_container_copy"
                 src="@/assets/cardPay/copy_m.png" alt="">
          </div>
        </div>
        <div class="moblie_pay_container_item moblie_pay_container_item_code">
          <div class="moblie_pay_container_label">收款标识码</div>
          <div class="moblie_pay_container_value">
            {{ repaymentData.trxNo }}
            <img @click="onCopy(repaymentData.trxNo)" class="moblie_pay_container_copy"
                 src="@/assets/cardPay/copy_m.png" alt="">
          </div>
        </div>
      </div>
      <div class="moblie_pay_container_tips">
        <img class="moblie_pay_container_tips_img" src="@/assets/cardPay/danger_m.png" alt="">
        <div class="moblie_pay_container_tips_text">
          请务必在付款时添加备注还款标识码，<br/>否则可能造成交易丢失。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {formatMoney} from "@/utils";

export default {
  name: "pay",
  props: {
    pageData: Object,
  },
  watch: {
    pageData(val) {
      console.log("watch", val)
      this.repaymentData = val;
    }
  },
  data() {
    return {
      setTimeoutID: undefined,
      repaymentData: {},

      hr: undefined,
      min: undefined,
      sec: undefined,
    };
  },
  methods: {
    formatMoney,
    onCopy(str) {
      let url = str;
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$toast("复制成功");
      oInput.remove();
    },
    futureDay() {
      clearTimeout(this.setTimeoutID)
      const end = Date.parse(new Date(this.pageData.expirationTime))
      const now = Date.parse(new Date())
      const msec = end - now
      let day = parseInt(msec / 1000 / 60 / 60 / 24)
      let hr = parseInt(msec / 1000 / 60 / 60 % 24) + (day * 24)
      let min = parseInt(msec / 1000 / 60 % 60)
      let sec = parseInt(msec / 1000 % 60)
      console.log(hr, min, sec)
      if (Object.is(hr, NaN) || Object.is(min, NaN) || Object.is(sec, NaN)) {
        hr = 0;
        min = 0;
        sec = 0;
      }
      hr = hr < 0 ? 0 : hr;
      min = min < 0 ? 0 : min;
      sec = sec < 0 ? 0 : sec;
      // this.day = day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec

      // this.repaymentData.day = this.day;
      this.repaymentData.hr = this.hr;
      this.repaymentData.min = this.min;
      this.repaymentData.sec = this.sec;
      this.setTimeoutID = setTimeout(() => {
        this.futureDay()
      }, 1000)

      if (!this.pageData.expirationTime) {
        return;
      }
      if (hr == 0 && min == 0 && sec == 0) {
        clearTimeout(this.setTimeoutID)
        this.$emit("expired")
      }
    },
    async init() {
      this.futureDay();
    },
  },
  created() {
    this.repaymentData = this.pageData;
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
    clearTimeout(this.setTimeoutID)
    this.setTimeoutID = null;
  }
};
</script>

<style lang="scss" scoped>
.moblie_pay {
  position: relative;
  height: 300px;
  background: linear-gradient(180deg, #1092FF 0%, rgba(10, 111, 255, 0.8) 65%, rgba(247, 247, 247, 0.8) 100%) #F7F7F7;

  .moblie_pay_header {
    width: 100%;
    height: 234px;
    padding-top: 40px;
    box-sizing: border-box;

    .moblie_pay_header_content {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      box-sizing: border-box;

      .moblie_pay_header_time_repayment {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;

      }

      .moblie_pay_header_time_box {
        margin-top: 15px;

        .moblie_pay_header_time_title {
          position: relative;
          margin: 0 auto;
          width: fit-content;
          font-size: 14px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFFFFF;

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: -15px;
            transform: translate(-100%, -50%);
            width: 30px;
            height: 2px;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
          }

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: -15px;
            transform: translate(100%, -50%);
            width: 30px;
            height: 2px;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%);
          }
        }

        .moblie_pay_header_time_info {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 24px;

          .moblie_pay_header_time_item {
            position: relative;
            width: 65px;
            height: 68px;
            background: url("~@/assets/cardPay/countdown_m.png") no-repeat;
            background-size: 100% 100%;
            margin-right: 45px;

            .moblie_pay_header_time {
              position: absolute;
              top: 6px;
              left: 50%;
              transform: translateX(-50%);
              font-size: 30px;
              font-family: Helvetica, Helvetica;
              font-weight: bold;
              color: #FFFFFF;
            }

            .moblie_pay_header_text {
              position: absolute;
              bottom: 6px;
              left: 50%;
              transform: translateX(-50%);
              font-size: 14px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 500;
              color: #FFFFFF;

            }

            &:last-child {
              margin-right: 0;

              &:before {
                display: none;
              }

              &:after {
                display: none;
              }
            }

            &:before {
              content: "";
              position: absolute;
              top: 20px;
              right: -22.5px;
              transform: translate(50%, -50%);
              width: 5px;
              height: 5px;
              background: #AFD5FF;
              border-radius: 50%;
            }

            &:after {
              content: "";
              position: absolute;
              bottom: 20px;
              right: -22.5px;
              transform: translate(50%, 50%);
              width: 5px;
              height: 5px;
              background: #AFD5FF;
              border-radius: 50%;
            }
          }
        }
      }
    }

  }

  .moblie_pay_container {
    width: calc(100% - 10px);
    margin: 0 auto;
    box-sizing: border-box;

    background: url("~@/assets/cardPay/bgi_m.png") no-repeat;
    background-size: 100% 100%;
    padding: 0 10px;
    padding-top: 40px;
    padding-bottom: 40px;


    .moblie_pay_container_info {
      width: 100%;
      margin: 0 auto;

      .moblie_pay_container_item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 20px;

        &:nth-child(2) {
          margin-top: 14px;
        }

        .moblie_pay_container_label {
          //flex: 1;
          width: 50%;
          font-size: 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #888888;

          word-break: break-all;
          word-wrap: break-word;

          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .moblie_pay_container_value {
          //flex: 1;
          width: 50%;
          font-size: 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #486184;

          word-break: break-all;
          word-wrap: break-word;

          display: flex;
          align-items: center;
        }

        .moblie_pay_container_copy {
          width: 15px;
          height: 15px;
          margin-left: 16px;
        }
      }

      .moblie_pay_container_item_header {
        margin: 0 20px;
        padding-left: 10px;

        .moblie_pay_container_label {
          position: relative;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #455D7B;

          &:before {
            content: "";
            width: 4px;
            height: 16px;
            background: #65A4FC;
            border-radius: 4px;

            position: absolute;
            left: -12px;

          }
        }

        .moblie_pay_container_value {
          justify-content: flex-end;

          .moblie_pay_header_img_box {
            .moblie_pay_header_img {
              width: 38px;
              height: 38px;
            }
          }
        }

      }

      .moblie_pay_container_item_code {
        .moblie_pay_container_value {
          font-size: 15px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #FFA700;

        }
      }
    }

    .moblie_pay_container_tips {
      margin: 0 auto;
      width: 100%;
      height: 60px;
      background: rgba(239, 210, 210, 0.3);
      display: flex;
      align-items: center;
      margin-top: 30px;
      padding: 0 20px;
      box-sizing: border-box;

      .moblie_pay_container_tips_img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        transform: translateY(-50%);
      }

      .moblie_pay_container_tips_text {
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #E43636;
        line-height: 20px;
      }
    }
  }
}
</style>
