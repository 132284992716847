<template>
  <div class="pay">
    <cardPayMoblie v-if="isMoblie" @expired="expired" :pageData="pageData"></cardPayMoblie>
    <cardPayComputer v-if="!isMoblie" @expired="expired" :pageData="pageData"></cardPayComputer>
    <!--    <div v-if="!isLoading">{{ pageData }}</div>-->
    <popup v-model="show"></popup>
  </div>
</template>

<script>
import axios from "axios";
import cardPayComputer from "@/views/components/cardPay_computer";
import cardPayMoblie from "@/views/components/cardPay_moblie";
import popup from "@/components/popup";

// const ROOT_PATH = "http://cardweb.slcash.xyz"
const ROOT_PATH = "http://192.168.1.13:9696"

export default {
  name: "pay",
  components: {
    cardPayMoblie,
    cardPayComputer,
    popup
  },
  data() {
    return {
      pageData: {},
      order: undefined,
      curIndex: 0,

      isMoblie: false,
      isLoading: false,
      show: false
    };
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
          /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    expired(){
      this.show = true;
    },
    compareTime(gettime) {
      var today = new Date()  //获取当前时间
      gettime = gettime.replace(/-/g, '/');
      var endTime = new Date(gettime) //自己的时间
      if (today.getTime() > endTime.getTime()) {
        return true
      } else {
        return false
      }
    },
    async init(trxNo) {
      let res = await axios.get("/api/checkout/info/" + trxNo);
      if (res.data.code == "200") {
        // res.data.data.expirationTime = "2023-11-30 15:59:30";
        this.pageData = res.data.data;
        this.isLoading = true;
        if (this.compareTime(this.pageData.expirationTime)) {
          this.show = true;
        }
      } else {
        this.show = true;
        this.isLoading = true;
        this.$toast(res.data.msg);
      }
      // let a = {
      //   "amount": "100000.00",
      //   "platformReference": "asdasd",
      //   "bankName": "abc bankabc bankabc bankabc bankabc bank",
      //   "accountName": "bvc",
      //   "accountNumber": "789789789789789789789789",
      //   "trxNo": "WENG231129101752nev7K",
      //   "expirationTime": "2023-11-30 09:55:04"
      // }
      // this.pageData = a;
      // console.log(this.pageData)
    },
  },
  created() {
    if (this.$route.query.trxNo) {
      this.init(this.$route.query.trxNo);
    }
    // this.init(JSON.parse(localStorage.getItem("androidData") || "{}"));
  },
  mounted() {
    let _this = this;
    window.onresize = function () {
      if (_this._isMobile()) {
        _this.isMoblie = true;
      } else {
        _this.isMoblie = false;
      }
    }
    if (this._isMobile()) {
      this.isMoblie = true;
    } else {
      this.isMoblie = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.pay {
  position: relative;
  height: 100%;
  min-height: 100vh;
  background: #F5F5F5;
}
</style>
