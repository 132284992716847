<!--// An highlighted block-->
<template>
  <div class="bg" v-if="value">
    <div class="pop" id="animation">
      <div class="main_box">
        <div class="title">交易已到期</div>
        <div class="computer_pay_header_time_info">
          <div class="computer_pay_header_time_item">
            <div class="computer_pay_header_time">00</div>
            <div class="computer_pay_header_text">时</div>
          </div>
          <div class="computer_pay_header_time_item">
            <div class="computer_pay_header_time">00</div>
            <div class="computer_pay_header_text">分</div>
          </div>
          <div class="computer_pay_header_time_item">
            <div class="computer_pay_header_time">00</div>
            <div class="computer_pay_header_text">秒</div>
          </div>
        </div>
        <img @click="clickOnThe" class="close_img" src="@/assets/cardPay/close.png" alt="">
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.main_box {
  position: relative;
  width: 335px;
  height: 223px;
  background: #FFFFFF;
  border-radius: 15px;
  padding-top: 45px;
  box-sizing: border-box;

  .title {
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #404960;
    text-align: center;

  }

  .computer_pay_header_time_info {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .computer_pay_header_time_item {
      position: relative;
      width: 65px;
      height: 68px;
      background: url("~@/assets/cardPay/countdown.png") no-repeat;
      background-size: 100% 100%;
      margin-right: 45px;

      .computer_pay_header_time {
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 30px;
        font-family: Helvetica, Helvetica;
        font-weight: bold;
        color: #2A8CFE;
      }

      .computer_pay_header_text {
        position: absolute;
        bottom: 6px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #3D96FE;
      }

      &:last-child {
        margin-right: 0;

        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      &:before {
        content: "";
        position: absolute;
        top: 20px;
        right: -22.5px;
        transform: translate(50%, -50%);
        width: 5px;
        height: 5px;
        background: #AFD5FF;
        border-radius: 50%;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 20px;
        right: -22.5px;
        transform: translate(50%, 50%);
        width: 5px;
        height: 5px;
        background: #AFD5FF;
        border-radius: 50%;
      }
    }
  }

  .close_img {
    position: absolute;
    width: 35px;
    height: 35px;
    left: 50%;
    bottom: -15px;
    transform: translate(-50%, 100%);
    cursor: pointer;
  }
}

.bg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
}

.pop {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /*left: -250px;*/
  /*top: -250px;*/

}

/*//左右摆动动画*/
#animation {
  -webkit-animation: wobble 1s 0.2s ease both;
  -moz-animation: wobble 1s 0.2s ease both;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes wobble {
  0% {
    -moz-transform: translateX(0%);
  }
  15% {
    -moz-transform: translateX(-25%) rotate(-5deg);
  }
  30% {
    -moz-transform: translateX(20%) rotate(3deg);
  }
  45% {
    -moz-transform: translateX(-15%) rotate(-3deg);
  }
  60% {
    -moz-transform: translateX(10%) rotate(2deg);
  }
  75% {
    -moz-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -moz-transform: translateX(0%);
  }
}

</style>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {}
  },
  mounted() {
    console.log(this.value)
  },
  methods: {
    clickOnThe() {
      this.$emit('input', !this.value)
      window.opener = null;
      window.open('', '_self');
      window.close();
    },
  }
}
</script>
