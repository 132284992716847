/* eslint-disable */
export function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        };
    };
    return fmt;
}

export function formatMoney(val) {
    if (typeof val == "number") {
        let str = val.toString();
        let reg =
            str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
        return str.replace(reg, "$1,");
    } else if (typeof val == "string") {
        let reg =
            val.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
        return val.replace(reg, "$1,");
    } else {
        return val
    }
}

/**
 * @desc 校验手机号码
 * @param {*} value 
 * @returns 
 */
export function checkPhone(value) {
    // const reg = /^[1-9]\d{9}$/;
    // let len = true;
    value = value.replace(" ","");
    console.log(value.length,value[0],value.length == 10 , value[0] == 0);
    if (value.length == 10 && value[0] == 0) {
      // len = false;
      return true;
    }else if (value.length == 9 && value[0] != 0) {
      // len = false;
      return true;
    }
    // if (reg.test(value)) {
    //   return true;
    // }
    return false;
}

/**
 * @desc 获取设备信息
 * @returns 
 */
export function getEquipment() {
    let deviceData = {
        w: null,
        h: null,
        system: null,
        latitude: null,
        longitude: null,
        hasOwnProperty: null,
        appCodeName: null,
        appName: null,
        appVersion: null,
        platform: null,
        userAgent: null,
        vendor: null,
        screenResolution: null,
    };
    // 分辨率
    deviceData.w = window.screen.width * window.devicePixelRatio;
    deviceData.h = window.screen.height * window.devicePixelRatio;
    // 设备类型
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        //alert(navigator.userAgent);
        // alert("这是IOS");
        deviceData.system = "ios";
    } else if (/(Android)/i.test(navigator.userAgent)) {
        //alert(navigator.userAgent);
        // alert("这是Android");
        deviceData.system = "android";
    } else {
        // alert("这是PC");
        deviceData.system = "other";
    }
    // 经纬度
    const showPosition = (wa) => {
        deviceData.latitude = wa.coords.latitude;
        deviceData.longitude = wa.coords.longitude;
    };
    const showError = (error) => {
        // locationByIp();
        switch (error.code) {
            case error.PERMISSION_DENIED:
                // alert("定位失败,用户拒绝请求地理定位");
                break;
            case error.POSITION_UNAVAILABLE:
                // alert("定位失败,位置信息是不可用");
                break;
            case error.TIMEOUT:
                // alert("定位失败,请求获取用户位置超时");
                break;
            case error.UNKNOWN_ERROR:
                // alert("定位失败,定位系统失效");
                break;
        }
    };
    navigator.geolocation.getCurrentPosition(showPosition, showError, {
        enableHighAcuracy: true, // 指示浏览器获取高精度的位置，默认为false
        timeout: 5000, // 指定获取地理位置的超时时间，默认不限时，单位为毫秒
        maximumAge: 2000, // 最长有效期，在重复获取地理位置时，此参数指定多久再次获取位置。
    });
    deviceData.screenResolution = deviceData?.w && deviceData?.h
        ? deviceData?.w + "," + deviceData?.h
        : null;
    // 是否是浏览器还是H5
    deviceData.hasOwnProperty =
        Object.prototype.hasOwnProperty.call(navigator) ?? false;
    deviceData.appCodeName = navigator?.appCodeName;
    deviceData.appName = navigator?.appName;
    deviceData.appVersion = navigator?.appVersion;
    deviceData.platform = navigator?.platform;
    deviceData.userAgent = navigator?.userAgent;
    deviceData.vendor = navigator?.vendor;
    return deviceData;
}

/**
 * @desc 校验密码
 * @param {*} value 
 * @returns 
 */
export function checkPass(value) {
    // const reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z-/:;()$&@“\.,?!’\[\]\{\}#%^*+=_\\\|~<>€£¥•]+$)(?![a-z0-9]+$)(?![a-z-/:;()$&@“\.,?!’\[\]\{\}#%^*+=_\\\|~<>€£¥•]+$)(?![0-9-/:;()$&@“\.,?!’\[\]\{\}#%^*+=_\\\|~<>€£¥•]+$)[a-zA-Z0-9-/:;()$&@“\.,?!’\[\]\{\}#%^*+=_\\\|~<>€£¥•]{8,15}$/;
    // return reg.test(value);
    return /^[\s\S]{8,20}$/.test(value);
}
